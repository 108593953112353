import dtLocaleMsgsEn from './en-us/locale.json' assert { type: 'json' };
import dtLocaleMsgsId from './id/locale.json' assert { type: 'json' };
import dtLocaleMsgsZh from './zh/locale.json' assert { type: 'json' };
const dtLocaleIntPlugin = () => {
    const localeSet = {
        'en-US': dtLocaleMsgsEn,
        'en': dtLocaleMsgsEn,
        'id': dtLocaleMsgsId,
        'zh': dtLocaleMsgsZh
    };
    return {
        locale: localeSet
    };
};
export { dtLocaleIntPlugin };
